<!--
 * @Descripttion:
 * @version:
 * @Author: 张天鹏
 * @Date: 2020-03-24 15:48:25
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-08-26 17:58:22
 -->
<template>
  <div class="mainbody">
    <div class="top">
      <div class="top_search">
        <div class="top_search-left"
             @click="companyClcik">{{ formlist.shortname ? formlist.shortname.length > 4 ? formlist.shortname.substr(0,4) : formlist.shortname : '保险公司' }}<img src="@/assets/cpk/images/xia.png"
               alt=""></div>
      </div>
      <!--  搜索框  -->
      <div class="searchbox clearfix">
        <div class="searchline clearfix">
          <button class="btnicon">
            <md-icon name="search"
                     size="lg"
                     color="#D6D6D6"></md-icon>
          </button>
          <input type="text"
                 placeholder="请输入产品名称"
                 v-model="formlist.cpname"
                 @keyup.13="search"
                 v-on:focus="cleardatafocus" />
          <button class="btnclear"
                  @click="clearseadata"
                  v-show="cleardatatrue&&formlist.cpname">
            <md-icon name="fail"
                     size="lg"
                     color="#666"></md-icon>
          </button>
        </div>
        <div class="searchTxt"
             @click="search">搜索</div>
      </div>
    </div>
    <div class="list"
         v-show="!isNull">
      <md-scroll-view ref="scrollView"
                      auto-reflow
                      :scrolling-x="false"
                      @end-reached="loadMorechange"
                      :loading-text="listtotalPage < listpageNo || listtotalPage == listpageNo?'没有要加载的数据啦...':'正在加载中'">
        <div class="product"
             v-for="(item,index) of reportList"
             :key="index"
             @click="comparergcl(item.cpcode)">
          <div class="product-lf">
            <img :src="item.iconhttp"
                 alt="">
          </div>
          <div class="product-rg">
            <div class="product-rg-cen">
              <div class="product-rg-top">
                <p>{{item.cpname}}</p>
              </div>
              <div class="product-rg-bom">
                <p>{{item.comname}}</p>
              </div>
            </div>
          </div>
        </div>
        <md-scroll-view-more slot="more"
                             :is-finished="listFinished" />
      </md-scroll-view>
    </div>
    <md-result-page :img-url="require('@/assets/abd/image/null.png')"
                    v-show="isNull"
                    subtext="要不然刷新试试？" />
    <!-- 保险公司赛选 -->
    <md-popup v-model="isSelectorShow"
              position="left"
              class="md-popupleft">
      <div class="md-examplepopup">
        <div class="popupleft-title">保险公司</div>
        <div style="height:80vh;overflow: auto;padding-left: 0.4rem;padding-bottom: 0.5rem;">
          <div class="titlecontent">
            <div v-for="(item,index) in shortName"
                 :key="index">
              <div :class="(!item.ischoose)?'company-title':'companytitle'"
                   :style="(!item.ischoose)?'':`backgroundColor:${colorprimary};`"
                   @click="choosefl(item,index)">{{item.text}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-cz">
        <div class="bottom-chongz"
             @click="reset">
          <div class="chongzhi">重置</div>
        </div>
        <div class="bottom-qued"
             :style="`backgroundColor: ${colorprimary};`"
             @click="onSelectorChoose">
          <div class="queding">确定</div>
        </div>
      </div>
    </md-popup>
  </div>
</template>
<script>
import { Selector, Field, FieldItem, Agree, Check, CheckGroup } from 'mand-mobile'
import { nextprodlist, selectShortName, personclassclist, getjhsinsurance } from '@/api/jhs/productlist/index'
import { getStorage, setStorage } from '@/lib/util'
import config from '@/config'
import loadMorechange from '@/mixins/loadMorechange'
const { cvu } = config
import store from "@/store";
export default {
  mixins: [loadMorechange],
  components: {
    [Selector.name]: Selector,
    [Field.name]: Field,
    [FieldItem.name]: FieldItem,
    [Agree.name]: Agree, // 单选
    [Check.name]: Check, // 多选
    [CheckGroup.name]: CheckGroup // 多选
  },
  data () {
    return {
      isSelectorShow: false,
      cleardatatrue: false,
      formlist: {
        baseid: '',
        cpname: ''
      },
      personNum: 1,
      msossurl: '',
      // pageNo: 1,
      // pageSize: 6,
      // loading: false,
      // reportList: [],
      // totalpage: 0,
      loadflag: false,
      listFinished: false,
      listpageNo: 1,
      pageSize: 8,
      reportList: [],
      listtotal: 0,
      listtotalPage: 0,
      isNull: false,
      isSelectorShow: false,
      selectorValue: '',
      shortName: [],
      prods: [],
      optfordata: [], // 选中数据
      proddatalist: [],
      selected: [],
      list: [],
      colorprimary: '',
    }
  },
  created () {
    this.msossurl = cvu
    this.theme = getStorage('theme', '')
    this.proddatalist = getStorage('proddatalist', [])
    this.getData()
    getjhsinsurance().then(res => {
      for (let item in res.data.data) {
        res.data.data[item].text = res.data.data[item].shortname
        res.data.data[item].value = res.data.data[item].baseid
      }
      this.shortName = res.data.data
    })
  },
  methods: {
    getData () {
      store.state.app.isloading = true;
      let data = {
        comid: this.$route.query.comid,
        empno: this.$route.query.empno,
        page: this.listpageNo,
        size: this.pageSize,
        baseid: this.formlist.baseid,
        cpname: this.formlist.cpname,
        list: this.proddatalist
      }
      nextprodlist(data).then(res => {
        this.reportList =
          this.reportList == []
            ? res.data.data.records
            : this.reportList.concat(res.data.data.records);
        if (this.reportList.length > 0) {
          this.isNull = false;
        } else {
          this.isNull = true;
        }
        this.listtotal = res.data.data.total
        this.listtotalPage = res.data.data.pages
        if (this.listtotalPage <= this.listpageNo) {
          this.listFinished = true
        } else {
          this.listFinished = false
          this.$refs.scrollView.finishLoadMore()
        }
        this.loadflag = true
      }).finally(() => {
        this.isSelectorShow = false
        store.state.app.isloading = false;
      })
    },
    comparergcl (cpcode) {
      this.list = []
      this.list.push(cpcode)
      console.log('111111111')
      if (this.list.length == 0) {
        if (this.$route.query.mold == 'jiating') {
          let data = {
            comid: this.$route.query.comid,
            familyno: this.$route.query.familyno,
            memid: this.$route.query.memid,
            list: this.list
          }
          personclassclist(data).then(res => {
            this.$router.push({
              path: '/report',
              query: {
                comid: this.$route.query.comid,
                familyno: this.$route.query.familyno,
                personkey: this.$route.query.personkey,
                empuserid: this.$route.query.empuserid,
                familyname: this.$route.query.familyname,
                fromwhere: this.$route.query.fromwhere,
                type: this.$route.query.type,
                empno: this.$route.query.empno,
                memid: this.$route.query.memid
              }
            })
          })
        } else if (this.$route.query.mold == 'geren') {
          let data = {
            comid: this.$route.query.comid,
            familyno: this.$route.query.familyno,
            memid: this.$route.query.memid,
            list: this.list
          }
          personclassclist(data).then(res => {
            this.$router.push({
              path: '/detail',
              query: {
                comid: this.$route.query.comid,
                personkey: this.$route.query.personkey,
                empuserid: this.$route.query.empuserid,
                familyname: this.$route.query.familyname,
                fromwhere: this.$route.query.fromwhere,
                type: this.$route.query.type,
                familyno: this.$route.query.familyno,
                memid: this.$route.query.memid,
                cardtype: this.$route.query.cardtype,
                cardno: this.$route.query.cardno,
                name: this.$route.query.name,
                empno: this.$route.query.empno
              }
            })
          })
        } else if (this.$route.query.mold == 'nbs') {
          let data = {
            comid: this.$route.query.comid,
            familyno: this.$route.query.familyno,
            memid: this.$route.query.memid,
            list: this.list
          }
          personclassclist(data).then(res => {
            this.$router.push({
              path: '/nbsReport',
              query: {
                familyname: this.$route.query.familyname,
                familyno: this.$route.query.familyno,
                comid: this.$route.query.comid,
                empno: this.$route.query.empno,
                fromwhere: this.$route.query.fromwhere,
                personkey: this.$route.query.personkey,
                custom: this.$route.query.custom
              }
            })
          })
        }
      } else {
        if (this.$route.query.mold == 'jiating') {
          setStorage('prodcpcodelist', this.list)
          this.$router.push({
            path: '/fillout',
            query: {
              mold: this.$route.query.mold,
              comid: this.$route.query.comid,
              familyno: this.$route.query.familyno,
              personkey: this.$route.query.personkey,
              empuserid: this.$route.query.empuserid,
              familyname: this.$route.query.familyname,
              fromwhere: this.$route.query.fromwhere,
              type: this.$route.query.type,
              empno: this.$route.query.empno,
              memid: this.$route.query.memid
            }
          })
        } else if (this.$route.query.mold == 'geren') {
          setStorage('prodcpcodelist', this.list)
          this.$router.push({
            path: '/fillout',
            query: {
              mold: this.$route.query.mold,
              comid: this.$route.query.comid,
              personkey: this.$route.query.personkey,
              empuserid: this.$route.query.empuserid,
              familyname: this.$route.query.familyname,
              fromwhere: this.$route.query.fromwhere,
              type: this.$route.query.type,
              familyno: this.$route.query.familyno,
              memid: this.$route.query.memid,
              cardtype: this.$route.query.cardtype,
              cardno: this.$route.query.cardno,
              name: this.$route.query.name,
              empno: this.$route.query.empno
            }
          })
        } else if (this.$route.query.mold == 'nbs') {
          setStorage('prodcpcodelist', this.list)
          this.$router.push({
            path: '/fillout',
            query: {
              mold: this.$route.query.mold,
              familyname: this.$route.query.familyname,
              familyno: this.$route.query.familyno,
              comid: this.$route.query.comid,
              empno: this.$route.query.empno,
              fromwhere: this.$route.query.fromwhere,
              personkey: this.$route.query.personkey,
              custom: this.$route.query.custom,
              memid: this.$route.query.memid
            }
          })
        }
      }
    },
    cleardatafocus () {
      this.cleardatatrue = true
    },
    cleardatablur () {
      this.cleardatatrue = false
    },
    clearseadata () {
      this.formlist.cpname = ''
      this.listpageNo = 1
      this.reportList = []
      this.getData()
    },
    deletedata () { // 清空选好的数据
      for (let item in this.optfordata) {
        this.optfordata[item].falg = false
      }
      this.selected = []
      this.getData()
      this.$forceUpdate()
    },
    search () {
      this.listFinished = false;
      this.loadflag = false;
      this.$refs.scrollView.finishLoadMore();
      this.listpageNo = 1
      this.reportList = []
      this.getData(this)
    },
    cleardata () {
      this.formlist = {
        baseid: '',
        cpname: ''
      }
      this.pageNo = 1
      this.getData()
      this.loading = false
      this.$refs.scrollView.finishLoadMore()
    },
    removedata (val, index) {
      for (let item in this.optfordata) {
        if (this.optfordata[item].cpcode === val.cpcode) {
          this.optfordata[item].falg = false
        }
      }
      this.selected.splice(index, 1)
      this.getData()
      this.$forceUpdate()
    },
    onChange (checked, children, index) {
      if (checked) {
        children.falg = true
        // 监听选中某一项，添加到已选项
        let a = 0
        this.optfordata.forEach(v => {
          if (children.cpcode === v.cpcode) {
            v.falg = true
            a++
          }
        })
        if (a == 0) {
          this.optfordata.push(children)
        }
      } else {
        children.falg = false
        for (let item in this.optfordata) {
          if (this.optfordata[item].cpcode === children.cpcode) {
            this.optfordata[item].falg = children.falg
          }
        }
      }
      this.selected = []
      for (let item in this.optfordata) {
        if (this.optfordata[item].falg) {
          this.selected.push(this.optfordata[item])
        }
      }
      this.$forceUpdate()
    },
    showSelector () {
      this.isSelectorShow = true
    },
    onSelectorChoose ({ baseid, shortname }) {
      this.listFinished = false;
      this.loadflag = false;
      this.$refs.scrollView.finishLoadMore();
      this.reportList = []
      this.listpageNo = 1
      this.getData()
    },
    // 打开保险公司选择弹窗
    companyClcik () {
      this.$nextTick(() => {
        this.isSelectorShow = true
      })
    },
    // 选择保险公司分类
    choosefl (item, index) {
      this.shortName.forEach((item, index) => {
        // item.ischoose = false
        this.$set(item, 'ischoose', false)
      })
      this.shortName[index].ischoose = !this.shortName[index].ischoose
      this.formlist.baseid = item.value
      this.formlist.shortname = item.text
      console.log(this.formlist.baseid)
    },
    // 重置保险公司选择
    reset () {
      this.shortName.forEach((item, index) => {
        item.ischoose = false
      })
      this.formlist.shortname = ''
      this.formlist.baseid = ''
    },
  }
}
</script>
<style lang="stylus" scoped>
.mainbody {
  background-color: #F7F6FB;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.product {
  height: 250px;
  background: #fff;
  border-bottom: 20px solid #eee;
}

.product-lf {
  float: left;
  width: 30vw;
  height: 100%;
  text-align: center;
  position: relative;
}

.product-lf img {
  border-radius: 5px;
  position: absolute; // 相对定位
  width: 180px;
  height: 160px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; // 使其垂直居中
}

.product-rg {
  float: left;
  width: 70vw;
  height: 100%;
  position: relative;
}

.product-rg-cen {
  position: absolute; // 相对定位
  height: 160px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; // 使其垂直居中
}

.product-rg-top {
  height: 50%;
}

.product-rg-top p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 3%;
}

.product-rg-top .md-agree {
  float: right;
  display: table-cell;
  vertical-align: middle;
  padding: 20px 0 20px 30px;
}

.product-rg-bom p {
  padding-top: 3%;
  font-size: 26px;
  color: #808080;
}

.top {
  // height 1.3rem
  height: 7vh;
  display: flex;
  // background-color #bdbdbd
}

.top_search {
  display: flex;
}

.top_search-left {
  display: flex;
  height: 100%;
  width: 2.5rem;
  justify-content: center;
  align-items: center;
  font-size: 0.38rem;
  font-weight: 400;
  font-family: Microsoft YaHei;
  color: rgba(102, 102, 102, 1);
}

.top_search-right {
  display: flex;
  width: 0.5rem;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.top_search-left img {
  margin-left: 0.2rem;
  width: 0.15rem;
  height: 0.15rem;
}

.searchbox {
  display: flex;
  // height: 0.87rem;
  height: 100%;
  width: 7.3rem;
  align-items: center;
  position: relative;

  .searchline {
    border-radius: 0.1rem;
    background: #F2F2F2;
    height: 0.87rem;
    width: 85%;
  }

  .btnicon {
    width: 16%;
    height: 0.87rem;
    border-radius: 0.3rem 0 0 0.3rem;
    background: none;
  }

  input {
    height: 0.87rem;
    border: none;
    background: none;
    font-size: 0.33rem;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: #A5A5A5;
    position: absolute;
  }

  .btnclear {
    background: none;
    position: absolute;
    right: 1.4rem;
    line-height: 0.95rem;
  }

  .searchTxt {
    font-size: 0.36rem;
    color: #474747;
    margin-left: 0.25rem;
  }
}

.list {
  height: 100%;
}

.clearfix:after, .clearfix:before {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

// .clearfix{
// *zoom 1
// }
.list /deep/ .scroll-view-more {
  padding-bottom: 65px;
}

.listp {
  height: 100px;
  line-height: 100px;
  padding-left: 62.5px;
  font-weight: 600;
  background: #fff;
  border-bottom: 1px solid #f6f6f6;
}

.cont {
  height: 25vh;
  background: #f6f6f6;
}

.conttop {
  margin-top: 20px;
  height: 100px;
  width: 100%;
  display: flex;
}

.conttopfl {
  width: 50%;
}

.conttoplf p {
  height: 100px;
  line-height: 100px;
  padding-left: 62.5px;
  font-weight: 600;
}

.conttoprg {
  width: 77%;
}

.conttoprg .md-button {
  float: right;
}

.conttoprg .md-button.block {
  width: 150px;
}

.conttoprg .md-button.default {
  background: none;
}

.contbom {
  height: 100%;
  display: flex;
}

.optfor {
  width: 250px;
  height: 250px;
  background: #fff;
  margin: 0 15px;
  border-radius: 10px;
  box-shadow: 0 0px 21px -2px #ccc;
}

.optfortp {
  padding-top: 35px;
  height: 50%;
  text-align: center;
  position: relative;
}

.optfortp img {
  height: 100px;
  width: 100px;
}

.optforbm {
  width: 90%;
  padding-top: 35px;
  margin: 0 auto;
  height: 50px;
}

.optforbm p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}

.optfortpbtn {
  position: absolute;
  right: 0;
  top: 0;
}

.optfortp .md-button {
  padding: 0;
  height: 60px;
}

.optfortp .md-button.block {
  width: 50px;
}

.compare {
  width: 100%;
  height: 6vh;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background: #fff;
  z-index: 5;
}

.comparelf p {
  line-height: 6vh;
  padding-left: 62.5px;
}

.comparelfpco {
  color: #e2e4ea;
}

.comparerg {
  width: 40%;
}

.comparerg .md-button.block {
  height: 100%;
}

.searchlfber {
  width: 20%;
  padding: 40px 0 40px 0px;
  text-align: center;
  position: relative;
  background: #fff;
}

.searchlfber img {
  width: 40px;
  height: 40px;
  position: absolute; // 相对定位
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto 0; // 使其垂直居中
}

.searchfr {
  float: left;
  width: 10%;
  height: 100%;
}

.search {
  background: #fff;
}

.search .searchlf {
  float: left;
  width: 60%;
  height: 100%;
}

.search .searchlfbe {
  width: 30%;
}

.searchfr .md-field /deep/ .md-button-content {
  color: #238acf;
}

.searchlfce /deep/ .md-field-content {
  border-radius: 50px;
  background: #f6f6f6;
}

.searchlfce /deep/ .md-field-item-left {
  padding-left: 30px;
}

.searchlfce .md-field {
  padding: 40px 40px 40px 0px;
}

.searchlfbe {
  display: flex;
}

.searchlfbe /deep/ .md-field {
  width: 80%;
  padding: 40px 0px 40px 40px;
}

.searchlf /deep/ .md-field-item-control {
  width: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/ .md-icon.icon-font.md {
  font-size: 45px;
  padding-right: 10px;
  background: #f6f6f6;
}

/deep/ .md-popup.inner-popup .md-popup-box {
  height: 45%;
}

/deep/ .md-selector-container {
  height: 100%;
}

.md-popupleft /deep/.md-slide-right {
  width: 82.6%;
  background-color: #ffffff;
}

.popupleft-title {
  margin-bottom: 0.61rem;
  font-size: 0.46rem;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  color: #3d3d3d;
  margin-top: 0.97rem;
  margin-left: 0.4rem;
}

.titlecontent {
  display: flex;
  flex-wrap: wrap;
}

.company-title {
  padding: 0.2rem 0.45rem;
  background-color: #f5f5f5;
  margin-right: 0.3rem;
  margin-bottom: 0.25rem;
  font-size: 0.33rem;
  border-radius: 0.1rem;
  color: #707070;
}

.companytitle {
  padding: 0.2rem 0.45rem;
  // background-color: #fb6822;
  margin-right: 0.3rem;
  margin-bottom: 0.25rem;
  font-size: 0.33rem;
  border-radius: 0.1rem;
  color: #fff;
}

.bottom-cz {
  height: 1.23rem;
  width: 100%;
  position: absolute;
  display: flex;
  bottom: 0;
  border-top: 0.01rem solid #f5f5f5;
}

.bottom-chongz {
  width: 50%;
  display: flex;
  align-items: center;
}

.bottom-qued {
  width: 50%;
  display: flex;
  align-items: center;
}

.chongzhi {
  margin: auto;
  color: #383838;
  font-family: PingFang-SC-Medium;
  font-size: 0.43rem;
}

.queding {
  margin: auto;
  color: #ffffff;
  font-family: PingFang-SC-Medium;
  font-size: 0.43rem;
}
</style>
